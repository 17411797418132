import { currencies, ordered_country_codes } from "data/options";
import * as yup from "yup";

export const groups = {
  main: [
    {
      id: "name",
      label: "Property Name",
      placeholder: "Enter Property Name",
      type: "text",
      required: true,
    },
  ],
  contact: [
    {
      id: "contact_name",
      label: "Contact Name",
      placeholder: "Enter Contact Name",
      type: "text",
      required: true,
    },

    {
      id: "contact_number",
      label: "Contact Number",
      type: "input-select",
      input_type: "text",
      placeholder: "Enter Contact Number",
      options_id: "contact_number_cc",
      options: ordered_country_codes,
      reverse: true,
      required: true,
    },

    {
      id: "ownership",
      label: "Ownership",
      type: "select",
      options: ["Self", "Others"],
      required: true,
    },
  ],
  Flat: [
    {
      id: "room_config",
      label: "Room Config",
      type: "select",
      options: ["1RK", "1BHK", "2BHK", "3BHK", "+3BHK"],
      required: true,
    },
    {
      id: "bath",
      label: "Bath",
      type: "select",
      options: ["1", "2", "3", "4", "5"],
      required: true,
    },
    {
      id: "floor",
      label: "Floor",
      type: "select",
      options: ["Ground", "First", "Middle", "Top"],
      required: true,
    },
    {
      id: "facing",
      label: "Entance Face Direction",
      type: "select",
      options: [
        "North",
        "East",
        "West",
        "South",
        "North-East",
        "North-West",
        "South-East",
        "South-West",
      ],
    },
    {
      id: "area",
      label: "Built-Up Area",
      type: "input-select",
      input_type: "number",
      placeholder: "e.g. 1000",
      options_id: "area_unit",
      options: ["SQFTS", "SQMS", "CENTS", "ACRES"].map((e) => ({
        label: e,
        id: e,
      })),
      required: true,
    },
    {
      id: "pay_type",
      label: "Payment Type",
      type: "select",
      options: ["Rent", "Sale"],
      required: true,
    },
  ],
  Plot: [
    {
      id: "plot_config",
      label: "Plot Type",
      type: "select",
      options: ["Residential", "Commercial", "Agricultural"],
      required: true,
    },
    {
      id: "area",
      label: "Plot Area",
      type: "input-select",
      input_type: "number",
      placeholder: "e.g. 1000",
      options_id: "area_unit",
      options: ["SQFTS", "SQMS", "CENTS", "ACRES"].map((e) => ({
        label: e,
        id: e,
      })),
      required: true,
    },
    {
      id: "pay_type",
      label: "Payment Type",
      type: "select",
      options: ["Sale", "Lease"],
      required: true,
    },
  ],
  Estab: [
    {
      id: "area",
      label: "Floor Area",
      type: "input-select",
      input_type: "number",
      placeholder: "e.g. 1000",
      options_id: "area_unit",
      options: ["SQFTS", "SQMS", "CENTS", "ACRES"].map((e) => ({
        label: e,
        id: e,
      })),
      required: true,
    },
    {
      id: "pay_type",
      label: "Payment Type",
      type: "select",
      options: ["Rent", "Sale"],
      required: true,
    },
  ],
  Rent: [
    {
      id: "rent_cost",
      label: "Rent",
      type: "input-select",
      input_type: "number",
      placeholder: "e.g. 1000",
      options_id: "currency",
      options: currencies,
      reverse: true,
      required: true,
    },
    {
      id: "deposit_cost",
      label: "Deposit",
      type: "input-select",
      input_type: "number",
      placeholder: "e.g. 1000",
      options_id: "currency",
      options: currencies,
      reverse: true,
      required: true,
    },
  ],
  Sale: [
    {
      id: "sale_cost",
      label: "Sale Cost",
      type: "input-select",
      input_type: "number",
      placeholder: "e.g. 1000",
      options_id: "currency",
      options: currencies,
      reverse: true,
      required: true,
    },
  ],
  Lease: [
    {
      id: "lease_cost",
      label: "Lease Cost",
      type: "input-select",
      input_type: "number",
      placeholder: "e.g. 1000",
      options_id: "currency",
      options: currencies,
      reverse: true,
      required: true,
    },
    {
      id: "lease_period",
      label: "Lease Duration (Days)",
      placeholder: "Enter Lease Duration",
      type: "number",
      required: true,
    },
  ],
};

export const initialValues = {
  images: [],
  name: "",
  type: "",
  room_config: "",
  room_config_specific: "",
  bath: "",
  floor: "",
  facing: "",
  plot_config: "",
  area: "",
  area_unit: "SQFTS",
  pay_type: "",
  currency: "INR",
  rent_cost: "",
  deposit_cost: "",
  sale_cost: "",
  lease_cost: "",
  lease_period: "",
  turnover_cost: "",
  contact_name: "",
  contact_number: "",
  contact_number_cc: "+91",
  ownership: "",
  position: { lat: 20.5937, lng: 78.9629 },
  address: "",
  description: "",
};

export const Form1Schema = yup.object().shape({
  position: yup.object().shape({
    lat: yup.number().required("Latitude is required"),
    lng: yup.number().required("Longitude is required"),
  }),
  address: yup.string().required("Address is Required"),
});

export const Form2Schema = Form1Schema.concat(
  yup.object().shape({
    type: yup.string().required("Pox type is Required"),
  })
);

export const Form3Schema = Form2Schema.concat(
  yup.object().shape({
    images: yup.array().of(
      yup
        .object({
          url: yup.string(),
          status: yup.string(),
        })
        .nullable()
    ),
    name: yup.string().required("Name is Required"),
    description: yup.string(),

    ownership: yup.string().required("Ownership Type is required"),
    contact_name: yup.string().required("Contact name is Required"),
    contact_number_cc: yup.string().required("Contact number cc is Required"),
    contact_number: yup
      .number()
      .positive("Contact number must be a positive number")
      .required("Contact number is Required"),
    area: yup
      .number()
      .positive("Area must be a positive number")
      .required("Area is required"),
    area_unit: yup.string().required("Area unit is required"),
    plot_config: yup.string().when("type", {
      is: (value) => value === "Plot",
      then: (schema) => schema.required("Plot type is Required"),
      otherwise: (schema) => schema,
    }),
    room_config: yup.string().when("type", {
      is: (value) => value === "Flat",
      then: (schema) => schema.required("Room configration is required"),
      otherwise: (schema) => schema,
    }),
    // bhk: yup
    //   .number()
    //   .nullable()
    //   .test("if_rendered", "Enter room config", function (value) {
    //     const { more, room_config } = this.parent;
    //     if (more && !room_config) {
    //       return value !== '';
    //     }
    //     return true;
    //   })
    //   .test(
    //     "greater-than-three",
    //     "Room Config must be greater than 3",
    //     (value) => {
    //       return value === null || value > 3;
    //     }
    //   )
    //   .test("less-than-fifty", "Room Config must be less than 50", (value) => {
    //     return value === null || value < 51;
    //   }),
    room_config_specific: yup
      .number()
      .min(4)
      .max(50)
      .when(["type", "room_config"], {
        is: (type, room_config) => type === "Flat" && room_config === "+3BHK",
        then: (schema) =>
          schema.required("Room configuration text is required"),
        otherwise: (schema) => schema,
      }),
    bath: yup.number().when("type", {
      is: (value) => value === "Flat",
      then: (schema) => schema.required("Bath is required"),
      otherwise: (schema) => schema,
    }),
    floor: yup.string().when("type", {
      is: (value) => value === "Flat",
      then: (schema) => schema.required("Floor is required"),
      otherwise: (schema) => schema,
    }),
    facing: yup.string().when("type", {
      is: (value) => value === "Flat",
      otherwise: (schema) => schema,
    }),
    pay_type: yup.string().required("Pay type is Required"),
    currency: yup.string().required("Currency is Required"),
    rent_cost: yup.string().when("pay_type", {
      is: (value) => value === "Rent",
      then: () =>
        yup
          .number()
          .positive("Rent amount must be a positive number")
          .required("Rent amount is required"),
    }),
    deposit_cost: yup.string().when("pay_type", {
      is: (value) => value === "Rent",
      then: () =>
        yup
          .number()
          .positive("Deposit amount must be a positive number")
          .required("Deposit amount is required"),
    }),
    sale_cost: yup.string().when("pay_type", {
      is: "Sale",
      then: () =>
        yup
          .number()
          .positive("Selling amount must be a positive number")
          .required("Selling amount is required"),
    }),
    lease_cost: yup.string().when("pay_type", {
      is: (value) => value === "Lease",
      then: () =>
        yup
          .number()
          .positive("Lease amount be a positive number")
          .required("Lease amount is required"),
    }),
    lease_period: yup.string().when("pay_type", {
      is: (value) => value === "Lease",
      then: () =>
        yup
          .number()
          .positive("Lease duration must be a positive number")
          .required("Lease duration is required"),
    }),
    turnover_cost: yup.string().when("type", {
      is: (value) => value === "Estab",
      then: () =>
        yup
          .number()
          .positive("Turnover amount must be a positive number")
          .required("Turnover amount is required"),
    }),
  })
);
