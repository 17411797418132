import "./styles.scss";

import { useMemo, useState } from "react";
import { Form1Schema, Form2Schema, Form3Schema, initialValues } from "./config";
import { useNavigate } from "react-router-dom";
import { useAxiosPrivate } from "hooks";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Form, Spinner } from "react-bootstrap";
import Form1 from "./tabs/Form1";
import Form2 from "./tabs/Form2";
import Form3 from "./tabs/Form3";

export default function PoxForm({ pre_data }) {
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const queryClient = useQueryClient();
  const [step, setStep] = useState(1);

  const validationSchema = useMemo(() => {
    switch (step) {
      case 1:
        return Form1Schema;
      case 2:
        return Form2Schema;
      case 3:
        return Form3Schema;

      default:
        return Form3Schema;
    }
  }, [step]);

  const formik = useFormik({
    initialValues: pre_data ?? initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const mutation = useMutation({
    mutationFn: async (data) => {
      if (pre_data) {
        return await axios.patch(`/poxes/${pre_data.id}`, data);
      }
      return await axios.post(`/poxes`, data);
    },
    onSuccess: (res) => {
      toast.success(res.data.message);

      if (pre_data) {
        queryClient.invalidateQueries(["pox", { id: pre_data.id }]);
        navigate(-1);
      } else {
        queryClient.invalidateQueries(["poxes"]);
        navigate("/", { replace: true });
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data.message || error.message);
    },
  });

  async function submit(values) {
    let stillUploading = false;
    let failedUpload = false;

    values.images?.forEach((e) => {
      if (e.status === "uploading") stillUploading = true;
      if (e.status === "error") failedUpload = true;
    });

    if (stillUploading)
      return toast.error(
        "Please wait for pending uploads to complete or cancel and retry if taking too long"
      );

    if (failedUpload)
      return toast.error("Please remove failed image upload and try again");

    const {
      // Flat
      room_config_specific,
      room_config,
      bath,
      floor,
      facing,

      // Plot
      plot_config,

      // Rent
      rent,
      deposit,

      // Sale
      sale,

      // Lease
      lease,
      lease_duration,

      // Estab
      turnover,

      ownership,
      ...rest
    } = values;
    let payload = {
      ...rest,
      ownership: ownership === "Self" ?? false,
    };

    switch (values.type) {
      case "Flat":
        payload = {
          ...payload,
          room_config:
            room_config === "+3BHK"
              ? `${room_config_specific}BHK`
              : room_config,
          bath,
          floor,
          facing,
        };

        break;

      case "Plot":
        payload = { ...payload, plot_config };
        break;

      case "Estab":
        payload = { ...payload, turnover };
        break;

      default:
        break;
    }

    switch (values.pay_type) {
      case "Rent":
        payload = { ...payload, rent, deposit };
        break;

      case "Sale":
        payload = { ...payload, sale };
        break;

      case "Lease":
        payload = { ...payload, lease, lease_duration };
        break;

      default:
        break;
    }

    mutation.mutate(payload);
  }

  const handleSubmit = (values) => {
    switch (step) {
      case 1:
        setStep(2);
        break;
      case 2:
        setStep(3);
        break;
      case 3:
        submit(values);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <div className="fields">
          {step === 1 && <Form1 formik={formik} />}
          {step === 2 && <Form2 formik={formik} />}
          {step === 3 && <Form3 formik={formik} />}
        </div>

        {/* footer */}
        <div className="footer d-flex justify-content-between align-items-center gap-5">
          <div
            className="d-flex flex-grow-1 rounded mw-30"
            style={{ height: 10, background: "lightgrey" }}
          >
            <div
              className="rounded"
              style={{
                backgroundColor: "#042954BD",
                height: 10,
                width: `${(step / 3) * 100}%`,
              }}
            />
          </div>
          <div className="d-flex justify-content-center gap-2">
            {step !== 1 && (
              <button
                type="button"
                className="d-flex justify-content-center align-items-center px-3 btn"
                style={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: 6,
                  height: 36,
                  borderWidth: 1,
                  borderColor: "#A0A0A0",
                  color: "#A0A0A0",
                  fontWeight: 500,
                }}
                onClick={() => setStep((prev) => prev - 1)}
              >
                Back
              </button>
            )}
            <button
              className="d-flex justify-content-center align-items-center px-3 btn"
              style={{
                backgroundColor: "#042954BD",
                borderRadius: 6,
                height: 36,
                color: "white",
                fontWeight: 500,
              }}
              type="submit"
            >
              {step === 3 ? "Submit" : "Next"}
            </button>
          </div>
        </div>
      </Form>

      {/* loading */}
      {mutation.isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 100,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            display: "grid",
            placeItems: "center",
          }}
        >
          <div
            style={{
              width: 360,
              padding: 24,
              backgroundColor: "white",
              borderRadius: 16,
              display: "inherit",
              placeItems: "center",
              gap: 8,
              textAlign: "center",
            }}
          >
            <span>
              {pre_data
                ? "Updating your Pox, please wait..."
                : "Creating your Pox, please wait..."}
            </span>
            <Spinner
              animation="border"
              variant="primary"
              role="status"
              size={64}
            />
          </div>
        </div>
      )}
    </>
  );
}
