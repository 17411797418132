import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { updateFilterQueries } from "redux/features/map.slice";
import { FILTERS_MAP } from "data/dataMaps";
import { Form } from "react-bootstrap";

const filtersDefault = {
  Flat: ["", "", "", "", "", ""],
  Plot: ["", "", "", "", "", ""],
  Estab: ["", "", "", ""],
};

const FilterItems = ({
  option,
  item,
  handleChange,
  setIsCheckedMore,
  setMore,
  more,
  filters,
  layer,
  isCheckedMore,
  handleChange2,
}) => {
  const optionId = `&${item.queryHead}=${
    option?.timeFrame || option?.values || option?.label
  }`;
  let isChecked = filters[layer][item.id] === optionId;
  if (more) {
    isChecked = false;
  }

  return (
    <>
      <label key={option.label} className="filter-entry" htmlFor={optionId}>
        <input
          type="radio"
          name={item.id}
          id={optionId}
          value={optionId}
          // checked={isChecked}
          checked={option.label === "3BHK+" ? isCheckedMore : isChecked}
          // onChange={handleChange}
          onChange={
            option.label === "3BHK+"
              ? () => {
                  setIsCheckedMore(true);
                  setMore("more");
                }
              : handleChange
          }
        />
        {option.label}
      </label>

      {option.label === "3BHK+" && more ? (
        <Form.Control
          placeholder="Enter BHK"
          style={{ width: "100px" }}
          onChange={(e) => {
            const searchValue = e.target.value;
            handleChange2(searchValue);
          }}
        />
      ) : null}
    </>
  );
};

const FilterSection = ({ close, active }) => {
  const dispatch = useDispatch();
  const { isLoading, layer, payType } = useSelector((state) => state.map);
  const [activeTab, setActive] = useState("");
  const [filters, setFilters] = useState(filtersDefault);
  const [more, setMore] = useState("");
  const [isCheckedMore, setIsCheckedMore] = useState(false);
  const filter_arr = useMemo(() => {
    const rest = FILTERS_MAP?.get(layer)?.Rest;
    const main = FILTERS_MAP?.get(layer)?.[payType];

    if (main && rest) return [...rest, ...main];
    return [];
  }, [layer, payType]);

  const toggleTab = (tab) => {
    setActive(tab === activeTab ? "" : tab);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFilters((prev) => {
      const newFilter = prev?.[layer]?.map((e, idx) => {
        if (idx === parseInt(name)) return value;
        return e;
      });
      return {
        ...prev,
        [layer]: newFilter,
      };
    });
    setMore("");
    setIsCheckedMore(false);
  };

  const handleChange2 = (searchValue) => {
    setFilters((prev) => {
      const newFilter = prev?.Flat?.map((e, idx) => {
        if (idx === 1) {
          return `&roomConfig=${searchValue}BHK`;
        }
        return e;
      });
      return {
        ...prev,
        [layer]: newFilter,
      };
    });
  };
  const onFilterReset = () => {
    setFilters(filtersDefault);
    dispatch(updateFilterQueries(""));
    close();
  };
  const onFilterApply = () => {
    const query = filters?.[layer]?.join("");
    dispatch(updateFilterQueries(query));
    close();
  };

  return (
    <div className={active ? "layer-tab" : "layer-tab d-none"} id="filter-tab">
      <div className="layer-header mb-3">
        <h5 className="h5 fw-bold m-0">Filters</h5>

        <button
          type="button"
          title="close"
          className="close-btn"
          onClick={close}
        >
          <FaTimes />
        </button>
      </div>
      <div className="accordion mb-3">
        {filter_arr?.map((item) => {
          return (
            <div key={item.id} className="filter-tab accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className={
                    activeTab === item.id
                      ? "accordion-button"
                      : "accordion-button collapsed"
                  }
                  type="button"
                  onClick={() => toggleTab(item.id)}
                >
                  {item.title}
                </button>
              </h2>

              <div
                className={
                  activeTab !== item.id
                    ? "filter-body accordion-collapse collapse"
                    : "filter-body accordion-collapse collapse show"
                }
                id={`collapse-${item.id}`}
              >
                <div className="accordion-body">
                  {item?.options?.map((option) => (
                    <FilterItems
                      key={option.label}
                      {...{
                        item,
                        option,
                        handleChange,
                        handleChange2,
                        more,
                        filters,
                        layer,
                        isCheckedMore,
                        setMore,
                        setIsCheckedMore,
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="my-1 pt-3 d-flex justify-content-center gap-3 border-top">
        <Button className="px-5" variant="danger" onClick={onFilterReset}>
          Reset
        </Button>

        <Button className="px-5" disabled={isLoading} onClick={onFilterApply}>
          Apply
        </Button>
      </div>
    </div>
  );
};

export default FilterSection;
