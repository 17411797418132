import { currencies } from "data/options";
import { groups } from "../config";
import ImageUpload from "../ImageUpload";
import InputRender from "../InputRender";

export default function Form3({ formik }) {
  return (
    <div>
      <div className="d-flex align-items-center gap-2 mb-2">
        <span className="fs-6 fw-bold">Enter Advance pox info</span>
      </div>

      <div>
        <ImageUpload
          value={formik.values.images}
          onUpload={(e) => formik.setFieldValue("images", e)}
        />

        {groups.main.map((e) => (
          <InputRender key={e.id} formik={formik} {...e} />
        ))}

        {groups[formik.values.type]?.map((e) => (
          <InputRender key={e.id} formik={formik} {...e} />
        ))}

        {groups[formik.values.pay_type]?.map((e) => (
          <InputRender key={e.id} formik={formik} {...e} />
        ))}

        {formik.values.type === "Estab" && formik.values.pay_type && (
          <InputRender
            formik={formik}
            {...{
              id: "turnover_cost",
              label: "Turnover",
              type: "input-select",
              input_type: "number",
              placeholder: "e.g. 1000",
              options_id: "currency",
              options: currencies,
              reverse: true,
            }}
          />
        )}

        <div
          className="mt-3 pt-4"
          style={{ borderTop: "1px solid rgb(33, 37, 41, .25)" }}
        >
          {groups.contact.map((e) => (
            <InputRender key={e.id} formik={formik} {...e} />
          ))}
        </div>
      </div>
    </div>
  );
}
