import axios from "axios";

const apikey = process.env.REACT_APP_OPENCAGEDATA_APIKEY;
const baseURL = `https://api.opencagedata.com/geocode/v1`;

const instance = axios.create({
  baseURL,
});

const reverseGeocoding = async ({ lat, lng }) => {
  try {
    const response = await instance.get(`json?key=${apikey}&q=${lat}+${lng}`);
    const address = response.data?.results?.[0]?.formatted;
    const country_code =
      response.data?.results?.[0]?.components.country_code.toUpperCase();
    let currency = "INR";
    const countries_response = await axios(
      `https://restcountries.com/v3.1/all`
    );
    const countries = countries_response.data;
    const country = countries.find((c) => c.cca2 === country_code);
    if (country) {
      currency = Object.keys(country.currencies)[0];
    } else {
      console.log("Country not found");
    }

    return { address, currency };
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};

const forwarGeocoding = () => {};

export { reverseGeocoding, forwarGeocoding };
