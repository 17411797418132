import FlatPNG from "assets/img/flat_png.png";
import PlotPNG from "assets/img/plot_png.png";
import EstabPNG from "assets/img/estab_png.png";

const options = [
  { label: "Flat", value: "Flat", img: FlatPNG },
  { label: "Plot", value: "Plot", img: PlotPNG },
  { label: "Establishment", value: "Estab", img: EstabPNG },
];

export default function Form2({ formik }) {
  return (
    <div>
      <div className="d-flex align-items-center gap-2 mb-2">
        <span className="fs-6 fw-bold">Select Pox Type</span>
      </div>
      <div className="d-flex flex-column gap-2">
        {options.map((option, index) => {
          const selected = formik.values.type === option.value;
          return (
            <button
              key={index}
              type="button"
              className="d-flex align-items-center justify-content-center gap-2 btn"
              onClick={() => {
                formik.setFieldValue("type", option.value);
              }}
              style={{
                cursor: "pointer",
                background: `url(${option.img}), rgba(0,0,0,0.5)`,
                width: "100%",
                height: 120,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderWidth: selected ? 6 : 0,
                borderColor: "#042954",
              }}
            >
              <span
                className="fs-6"
                style={{ color: "#fff", fontWeight: "semibold" }}
              >
                {option.label}
              </span>
            </button>
          );
        })}
      </div>
      {formik.errors.type && (
        <span style={{ color: "red" }}>{formik.errors.type}</span>
      )}
    </div>
  );
}
