import { currencies } from "data/options";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { updateCurrency } from "redux/features/map.slice";
import ReactSelect from "react-select";

export default function CurrencyFilter() {
  const currency = useSelector((state) => state.map.currency);
  const dispatch = useDispatch();
  const location = useLocation();

  if (location.pathname !== "/") return null;

  return (
    <div className="currency-filter">
      <ReactSelect
        className="select-dropdown"
        options={[{ id: "", label: "View ALL", enabled: true }, ...currencies]}
        isSearchable
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.id}
        value={currencies.find((e) => e.id === currency)}
        onChange={(e) => {
          dispatch(updateCurrency(e.id));
        }}
      />
    </div>
  );
}
