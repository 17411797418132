import LocationPicker from "../LocationPicker";

export default function Form1({ formik }) {
  return (
    <div>
      <div>
        <LocationPicker formik={formik} />
      </div>
    </div>
  );
}
