import { Form, InputGroup } from "react-bootstrap";
import ReactSelect from "react-select";

export default function InputRender({ formik, type, id, label, ...props }) {
  if (type === "input-select") {
    return (
      <Form.Group controlId={id} className="mb-4">
        <Form.Label className="fw-bold d-flex gap-1">
          {props.required && <span className="text-danger">*</span>}
          {label}
        </Form.Label>
        <div>
          <InputGroup className={props.reverse ? "reverse" : ""}>
            <Form.Control
              type={props.input_type}
              name={id}
              placeholder={props.placeholder}
              value={formik.values?.[id]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched?.[id] && formik.errors[id]}
            />
            <ReactSelect
              styles={{
                container: (provided) => ({
                  ...provided,
                  // zIndex: 10,
                  width: 80,
                  maxWidth: 80,
                  padding: 0,
                }),
                control: (provided) => ({
                  ...provided,
                  border:
                    formik.touched?.[id] && formik.errors[id]
                      ? "thin solid red"
                      : "1px solid #ced4da",
                  borderRight: props.reverse && "none",
                  // borderLeft: !props.reverse && "none",
                  borderTopRightRadius: props.reverse && "0",
                  borderBottomRightRadius: props.reverse && "0",
                  borderTopLeftRadius: !props.reverse && "0",
                  borderBottomLeftRadius: !props.reverse && "0",
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 80,
                }),
                indicatorsContainer: (provided) => ({
                  ...provided,
                  display: "none",
                }),
              }}
              options={props.options}
              isSearchable
              getOptionValue={(e) => e.label}
              getOptionLabel={(e) => e.id}
              value={props.options.find(
                (e) => e.id === formik.values[props.options_id]
              )}
              onChange={(e) => {
                formik.setFieldValue(props.options_id, e.id);
              }}
            />
          </InputGroup>

          {formik.touched?.[id] && formik.errors[id] && (
            <span
              className="text-danger"
              style={{ fontSize: ".875em", marginTop: ".2rem", width: "100%" }}
            >
              {formik.errors[id]}
            </span>
          )}
        </div>
      </Form.Group>
    );
  }
  if (type === "select") {
    return (
      <Form.Group controlId={id} className="mb-4">
        <Form.Label className="fw-bold d-flex gap-1">
          {props.required && <span className="text-danger">*</span>}
          {label}
        </Form.Label>
        <div>
          <div className="d-flex gap-2 flex-wrap">
            {props?.options.map((e) => (
              <Form.Check
                key={e}
                className={`custom-radio-wrapper ${
                  formik.values[id] === e ? "active" : ""
                }`}
              >
                <Form.Check.Label
                  htmlFor={id}
                  style={{
                    fontSize: "0.85rem",
                    fontWeight: "500",
                    margin: 0,
                    lineHeight: 0,
                  }}
                >
                  {e}
                </Form.Check.Label>
                <Form.Check.Input
                  type="radio"
                  name={id}
                  id={e}
                  value={e}
                  onChange={() => {
                    if (id !== "room_config") {
                      formik.setFieldValue(id, e);
                    } else {
                      formik.setFieldValue(id, e);
                      formik.setFieldValue("room_config_specific", undefined);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  style={{ cursor: "pointer" }}
                />
              </Form.Check>
            ))}
          </div>

          {formik.touched?.[id] && formik.errors[id] && (
            <span
              className="text-danger"
              style={{ fontSize: ".875em", marginTop: ".2rem", width: "100%" }}
            >
              {formik.errors[id]}
            </span>
          )}
        </div>
        {id === "room_config" && formik.values.room_config === "+3BHK" && (
          <div className="mt-2">
            <Form.Control
              type="number"
              min={1}
              max={50}
              name="room_config_specific"
              placeholder="Enter BHK"
              style={{ width: "150px" }}
              onChange={(e) => {
                const updatedValue = e.target.value;
                formik.setFieldValue("room_config_specific", updatedValue);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.room_config_specific ?? ""}
            />
            {formik.touched.room_config_specific &&
              formik.errors.room_config_specific && (
                <span
                  className="text-danger"
                  style={{
                    fontSize: ".875em",
                    marginTop: ".2rem",
                    width: "100%",
                  }}
                >
                  {formik.errors.room_config_specific}
                </span>
              )}
          </div>
        )}
      </Form.Group>
    );
  }

  return (
    <Form.Group controlId={id} className="mb-4">
      <Form.Label className="fw-bold d-flex gap-1">
        {props.required && <span className="text-danger">*</span>}
        {label}
      </Form.Label>
      <div>
        <Form.Control
          type={type}
          {...props}
          name={id}
          value={formik.values?.[id]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={formik.touched?.[id] && formik.errors[id]}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors[id]}
        </Form.Control.Feedback>
      </div>
    </Form.Group>
  );
}
