export const currencies = [
  { id: "AED", label: "AED", enabled: true },
  { id: "AFN", label: "AFN", enabled: true },
  { id: "ALL", label: "ALL", enabled: true },
  { id: "AMD", label: "AMD", enabled: true },
  { id: "ANG", label: "ANG", enabled: true },
  { id: "AOA", label: "AOA", enabled: true },
  { id: "ARS", label: "ARS", enabled: true },
  { id: "AUD", label: "AUD", enabled: true },
  { id: "AWG", label: "AWG", enabled: true },
  { id: "AZN", label: "AZN", enabled: true },
  { id: "BAM", label: "BAM", enabled: true },
  { id: "BBD", label: "BBD", enabled: true },
  { id: "BDT", label: "BDT", enabled: true },
  { id: "BGN", label: "BGN", enabled: true },
  { id: "BHD", label: "BHD", enabled: true },
  { id: "BIF", label: "BIF", enabled: true },
  { id: "BMD", label: "BMD", enabled: true },
  { id: "BND", label: "BND", enabled: true },
  { id: "BOB", label: "BOB", enabled: true },
  { id: "BRL", label: "BRL", enabled: true },
  { id: "BSD", label: "BSD", enabled: true },
  { id: "BTN", label: "BTN", enabled: true },
  { id: "BWP", label: "BWP", enabled: true },
  { id: "BYN", label: "BYN", enabled: true },
  { id: "BZD", label: "BZD", enabled: true },
  { id: "CAD", label: "CAD", enabled: true },
  { id: "CDF", label: "CDF", enabled: true },
  { id: "CHF", label: "CHF", enabled: true },
  { id: "CLP", label: "CLP", enabled: true },
  { id: "CNY", label: "CNY", enabled: true },
  { id: "COP", label: "COP", enabled: true },
  { id: "CRC", label: "CRC", enabled: true },
  { id: "CUP", label: "CUP", enabled: true },
  { id: "CVE", label: "CVE", enabled: true },
  { id: "CZK", label: "CZK", enabled: true },
  { id: "DJF", label: "DJF", enabled: true },
  { id: "DKK", label: "DKK", enabled: true },
  { id: "DOP", label: "DOP", enabled: true },
  { id: "DZD", label: "DZD", enabled: true },
  { id: "EGP", label: "EGP", enabled: true },
  { id: "ERN", label: "ERN", enabled: true },
  { id: "ETB", label: "ETB", enabled: true },
  { id: "EUR", label: "EUR", enabled: true },
  { id: "FJD", label: "FJD", enabled: true },
  { id: "FKP", label: "FKP", enabled: true },
  { id: "FOK", label: "FOK", enabled: true },
  { id: "GBP", label: "GBP", enabled: true },
  { id: "GEL", label: "GEL", enabled: true },
  { id: "GGP", label: "GGP", enabled: true },
  { id: "GHS", label: "GHS", enabled: true },
  { id: "GIP", label: "GIP", enabled: true },
  { id: "GMD", label: "GMD", enabled: true },
  { id: "GNF", label: "GNF", enabled: true },
  { id: "GTQ", label: "GTQ", enabled: true },
  { id: "GYD", label: "GYD", enabled: true },
  { id: "HKD", label: "HKD", enabled: true },
  { id: "HNL", label: "HNL", enabled: true },
  { id: "HRK", label: "HRK", enabled: true },
  { id: "HTG", label: "HTG", enabled: true },
  { id: "HUF", label: "HUF", enabled: true },
  { id: "IDR", label: "IDR", enabled: true },
  { id: "ILS", label: "ILS", enabled: true },
  { id: "IMP", label: "IMP", enabled: true },
  { id: "INR", label: "INR", enabled: true },
  { id: "IQD", label: "IQD", enabled: true },
  { id: "IRR", label: "IRR", enabled: true },
  { id: "ISK", label: "ISK", enabled: true },
  { id: "JEP", label: "JEP", enabled: true },
  { id: "JMD", label: "JMD", enabled: true },
  { id: "JOD", label: "JOD", enabled: true },
  { id: "JPY", label: "JPY", enabled: true },
  { id: "KES", label: "KES", enabled: true },
  { id: "KGS", label: "KGS", enabled: true },
  { id: "KHR", label: "KHR", enabled: true },
  { id: "KID", label: "KID", enabled: true },
  { id: "KMF", label: "KMF", enabled: true },
  { id: "KRW", label: "KRW", enabled: true },
  { id: "KWD", label: "KWD", enabled: true },
  { id: "KYD", label: "KYD", enabled: true },
  { id: "KZT", label: "KZT", enabled: true },
  { id: "LAK", label: "LAK", enabled: true },
  { id: "LBP", label: "LBP", enabled: true },
  { id: "LKR", label: "LKR", enabled: true },
  { id: "LRD", label: "LRD", enabled: true },
  { id: "LSL", label: "LSL", enabled: true },
  { id: "LYD", label: "LYD", enabled: true },
  { id: "MAD", label: "MAD", enabled: true },
  { id: "MDL", label: "MDL", enabled: true },
  { id: "MGA", label: "MGA", enabled: true },
  { id: "MKD", label: "MKD", enabled: true },
  { id: "MMK", label: "MMK", enabled: true },
  { id: "MNT", label: "MNT", enabled: true },
  { id: "MOP", label: "MOP", enabled: true },
  { id: "MRU", label: "MRU", enabled: true },
  { id: "MUR", label: "MUR", enabled: true },
  { id: "MVR", label: "MVR", enabled: true },
  { id: "MWK", label: "MWK", enabled: true },
  { id: "MXN", label: "MXN", enabled: true },
  { id: "MYR", label: "MYR", enabled: true },
  { id: "MZN", label: "MZN", enabled: true },
  { id: "NAD", label: "NAD", enabled: true },
  { id: "NGN", label: "NGN", enabled: true },
  { id: "NIO", label: "NIO", enabled: true },
  { id: "NOK", label: "NOK", enabled: true },
  { id: "NPR", label: "NPR", enabled: true },
  { id: "NZD", label: "NZD", enabled: true },
  { id: "OMR", label: "OMR", enabled: true },
  { id: "PAB", label: "PAB", enabled: true },
  { id: "PEN", label: "PEN", enabled: true },
  { id: "PGK", label: "PGK", enabled: true },
  { id: "PHP", label: "PHP", enabled: true },
  { id: "PKR", label: "PKR", enabled: true },
  { id: "PLN", label: "PLN", enabled: true },
  { id: "PYG", label: "PYG", enabled: true },
  { id: "QAR", label: "QAR", enabled: true },
  { id: "RON", label: "RON", enabled: true },
  { id: "RSD", label: "RSD", enabled: true },
  { id: "RUB", label: "RUB", enabled: true },
  { id: "RWF", label: "RWF", enabled: true },
  { id: "SAR", label: "SAR", enabled: true },
  { id: "SBD", label: "SBD", enabled: true },
  { id: "SCR", label: "SCR", enabled: true },
  { id: "SDG", label: "SDG", enabled: true },
  { id: "SEK", label: "SEK", enabled: true },
  { id: "SGD", label: "SGD", enabled: true },
  { id: "SHP", label: "SHP", enabled: true },
  { id: "SLE", label: "SLE", enabled: true },
  { id: "SLL", label: "SLL", enabled: true },
  { id: "SOS", label: "SOS", enabled: true },
  { id: "SRD", label: "SRD", enabled: true },
  { id: "SSP", label: "SSP", enabled: true },
  { id: "STN", label: "STN", enabled: true },
  { id: "SYP", label: "SYP", enabled: true },
  { id: "SZL", label: "SZL", enabled: true },
  { id: "THB", label: "THB", enabled: true },
  { id: "TJS", label: "TJS", enabled: true },
  { id: "TMT", label: "TMT", enabled: true },
  { id: "TND", label: "TND", enabled: true },
  { id: "TOP", label: "TOP", enabled: true },
  { id: "TRY", label: "TRY", enabled: true },
  { id: "TTD", label: "TTD", enabled: true },
  { id: "TVD", label: "TVD", enabled: true },
  { id: "TWD", label: "TWD", enabled: true },
  { id: "TZS", label: "TZS", enabled: true },
  { id: "UAH", label: "UAH", enabled: true },
  { id: "UGX", label: "UGX", enabled: true },
  { id: "USD", label: "USD", enabled: true },
  { id: "UYU", label: "UYU", enabled: true },
  { id: "UZS", label: "UZS", enabled: true },
  { id: "VED", label: "VED", enabled: true },
  { id: "VES", label: "VES", enabled: true },
  { id: "VND", label: "VND", enabled: true },
  { id: "VUV", label: "VUV", enabled: true },
  { id: "WST", label: "WST", enabled: true },
  { id: "XAF", label: "XAF", enabled: true },
  { id: "XCD", label: "XCD", enabled: true },
  { id: "XOF", label: "XOF", enabled: true },
  { id: "XPF", label: "XPF", enabled: true },
  { id: "YER", label: "YER", enabled: true },
  { id: "ZAR", label: "ZAR", enabled: true },
  { id: "ZMW", label: "ZMW", enabled: true },
  { id: "ZWL", label: "ZWL", enabled: true },
];

export const country_codes = [
  { id: "+93", country_id: "AF", label: "+93", enabled: true }, // Afghanistan
  { id: "+355", country_id: "AL", label: "+355", enabled: true }, // Albania
  { id: "+213", country_id: "DZ", label: "+213", enabled: true }, // Algeria
  { id: "+1-684", country_id: "AS", label: "+1-684", enabled: true }, // American Samoa
  { id: "+376", country_id: "AD", label: "+376", enabled: true }, // Andorra
  { id: "+244", country_id: "AO", label: "+244", enabled: true }, // Angola
  { id: "+1-264", country_id: "AI", label: "+1-264", enabled: true }, // Anguilla
  { id: "+672", country_id: "AQ", label: "+672", enabled: true }, // Antarctica
  { id: "+1-268", country_id: "AG", label: "+1-268", enabled: true }, // Antigua and Barbuda
  { id: "+54", country_id: "AR", label: "+54", enabled: true }, // Argentina
  { id: "+374", country_id: "AM", label: "+374", enabled: true }, // Armenia
  { id: "+297", country_id: "AW", label: "+297", enabled: true }, // Aruba
  { id: "+61", country_id: "AU", label: "+61", enabled: true }, // Australia
  { id: "+43", country_id: "AT", label: "+43", enabled: true }, // Austria
  { id: "+994", country_id: "AZ", label: "+994", enabled: true }, // Azerbaijan
  { id: "+1-242", country_id: "BS", label: "+1-242", enabled: true }, // Bahamas
  { id: "+973", country_id: "BH", label: "+973", enabled: true }, // Bahrain
  { id: "+880", country_id: "BD", label: "+880", enabled: true }, // Bangladesh
  { id: "+1-246", country_id: "BB", label: "+1-246", enabled: true }, // Barbados
  { id: "+375", country_id: "BY", label: "+375", enabled: true }, // Belarus
  { id: "+32", country_id: "BE", label: "+32", enabled: true }, // Belgium
  { id: "+501", country_id: "BZ", label: "+501", enabled: true }, // Belize
  { id: "+229", country_id: "BJ", label: "+229", enabled: true }, // Benin
  { id: "+1-441", country_id: "BM", label: "+1-441", enabled: true }, // Bermuda
  { id: "+975", country_id: "BT", label: "+975", enabled: true }, // Bhutan
  { id: "+591", country_id: "BO", label: "+591", enabled: true }, // Bolivia
  { id: "+387", country_id: "BA", label: "+387", enabled: true }, // Bosnia and Herzegovina
  { id: "+267", country_id: "BW", label: "+267", enabled: true }, // Botswana
  { id: "+55", country_id: "BR", label: "+55", enabled: true }, // Brazil
  { id: "+673", country_id: "BN", label: "+673", enabled: true }, // Brunei
  { id: "+359", country_id: "BG", label: "+359", enabled: true }, // Bulgaria
  { id: "+226", country_id: "BF", label: "+226", enabled: true }, // Burkina Faso
  { id: "+257", country_id: "BI", label: "+257", enabled: true }, // Burundi
  { id: "+855", country_id: "KH", label: "+855", enabled: true }, // Cambodia
  { id: "+237", country_id: "CM", label: "+237", enabled: true }, // Cameroon
  { id: "+1", country_id: "CA", label: "+1", enabled: true }, // Canada
  { id: "+238", country_id: "CV", label: "+238", enabled: true }, // Cape Verde
  { id: "+1-345", country_id: "KY", label: "+1-345", enabled: true }, // Cayman Islands
  { id: "+236", country_id: "CF", label: "+236", enabled: true }, // Central African Republic
  { id: "+235", country_id: "TD", label: "+235", enabled: true }, // Chad
  { id: "+56", country_id: "CL", label: "+56", enabled: true }, // Chile
  { id: "+86", country_id: "CN", label: "+86", enabled: true }, // China
  { id: "+57", country_id: "CO", label: "+57", enabled: true }, // Colombia
  { id: "+269", country_id: "KM", label: "+269", enabled: true }, // Comoros
  { id: "+682", country_id: "CK", label: "+682", enabled: true }, // Cook Islands
  { id: "+506", country_id: "CR", label: "+506", enabled: true }, // Costa Rica
  { id: "+225", country_id: "CI", label: "+225", enabled: true }, // Côte d'Ivoire
  { id: "+385", country_id: "HR", label: "+385", enabled: true }, // Croatia
  { id: "+53", country_id: "CU", label: "+53", enabled: true }, // Cuba
  { id: "+357", country_id: "CY", label: "+357", enabled: true }, // Cyprus
  { id: "+420", country_id: "CZ", label: "+420", enabled: true }, // Czech Republic
  { id: "+45", country_id: "DK", label: "+45", enabled: true }, // Denmark
  { id: "+253", country_id: "DJ", label: "+253", enabled: true }, // Djibouti
  { id: "+1-767", country_id: "DM", label: "+1-767", enabled: true }, // Dominica
  { id: "+1-809", country_id: "DO", label: "+1-809", enabled: true }, // Dominican Republic
  { id: "+593", country_id: "EC", label: "+593", enabled: true }, // Ecuador
  { id: "+20", country_id: "EG", label: "+20", enabled: true }, // Egypt
  { id: "+503", country_id: "SV", label: "+503", enabled: true }, // El Salvador
  { id: "+240", country_id: "GQ", label: "+240", enabled: true }, // Equatorial Guinea
  { id: "+291", country_id: "ER", label: "+291", enabled: true }, // Eritrea
  { id: "+372", country_id: "EE", label: "+372", enabled: true }, // Estonia
  { id: "+251", country_id: "ET", label: "+251", enabled: true }, // Ethiopia
  { id: "+500", country_id: "FK", label: "+500", enabled: true }, // Falkland Islands
  { id: "+298", country_id: "FO", label: "+298", enabled: true }, // Faroe Islands
  { id: "+679", country_id: "FJ", label: "+679", enabled: true }, // Fiji
  { id: "+358", country_id: "FI", label: "+358", enabled: true }, // Finland
  { id: "+33", country_id: "FR", label: "+33", enabled: true }, // France
  { id: "+241", country_id: "GA", label: "+241", enabled: true }, // Gabon
  { id: "+220", country_id: "GM", label: "+220", enabled: true }, // Gambia
  { id: "+995", country_id: "GE", label: "+995", enabled: true }, // Georgia
  { id: "+49", country_id: "DE", label: "+49", enabled: true }, // Germany
  { id: "+233", country_id: "GH", label: "+233", enabled: true }, // Ghana
  { id: "+350", country_id: "GI", label: "+350", enabled: true }, // Gibraltar
  { id: "+30", country_id: "GR", label: "+30", enabled: true }, // Greece
  { id: "+299", country_id: "GL", label: "+299", enabled: true }, // Greenland
  { id: "+1-473", country_id: "GD", label: "+1-473", enabled: true }, // Grenada
  { id: "+1-671", country_id: "GU", label: "+1-671", enabled: true }, // Guam
  { id: "+502", country_id: "GT", label: "+502", enabled: true }, // Guatemala
  { id: "+44-1481", country_id: "GG", label: "+44-1481", enabled: true }, // Guernsey
  { id: "+224", country_id: "GN", label: "+224", enabled: true }, // Guinea
  { id: "+245", country_id: "GW", label: "+245", enabled: true }, // Guinea-Bissau
  { id: "+592", country_id: "GY", label: "+592", enabled: true }, // Guyana
  { id: "+509", country_id: "HT", label: "+509", enabled: true }, // Haiti
  { id: "+504", country_id: "HN", label: "+504", enabled: true }, // Honduras
  { id: "+852", country_id: "HK", label: "+852", enabled: true }, // Hong Kong
  { id: "+36", country_id: "HU", label: "+36", enabled: true }, // Hungary
  { id: "+354", country_id: "IS", label: "+354", enabled: true }, // Iceland
  { id: "+91", country_id: "IN", label: "+91", enabled: true }, // India
  { id: "+62", country_id: "ID", label: "+62", enabled: true }, // Indonesia
  { id: "+98", country_id: "IR", label: "+98", enabled: true }, // Iran
  { id: "+964", country_id: "IQ", label: "+964", enabled: true }, // Iraq
  { id: "+353", country_id: "IE", label: "+353", enabled: true }, // Ireland
  { id: "+44-1624", country_id: "IM", label: "+44-1624", enabled: true }, // Isle of Man
  { id: "+972", country_id: "IL", label: "+972", enabled: true }, // Israel
  { id: "+39", country_id: "IT", label: "+39", enabled: true }, // Italy
  { id: "+1-876", country_id: "JM", label: "+1-876", enabled: true }, // Jamaica
  { id: "+81", country_id: "JP", label: "+81", enabled: true }, // Japan
  { id: "+44-1534", country_id: "JE", label: "+44-1534", enabled: true }, // Jersey
  { id: "+962", country_id: "JO", label: "+962", enabled: true }, // Jordan
  { id: "+7", country_id: "KZ", label: "+7", enabled: true }, // Kazakhstan
  { id: "+254", country_id: "KE", label: "+254", enabled: true }, // Kenya
  { id: "+686", country_id: "KI", label: "+686", enabled: true }, // Kiribati
  { id: "+850", country_id: "KP", label: "+850", enabled: true }, // North Korea
  { id: "+82", country_id: "KR", label: "+82", enabled: true }, // South Korea
  { id: "+965", country_id: "KW", label: "+965", enabled: true }, // Kuwait
  { id: "+996", country_id: "KG", label: "+996", enabled: true }, // Kyrgyzstan
  { id: "+856", country_id: "LA", label: "+856", enabled: true }, // Laos
  { id: "+371", country_id: "LV", label: "+371", enabled: true }, // Latvia
  { id: "+961", country_id: "LB", label: "+961", enabled: true }, // Lebanon
  { id: "+266", country_id: "LS", label: "+266", enabled: true }, // Lesotho
  { id: "+231", country_id: "LR", label: "+231", enabled: true }, // Liberia
  { id: "+218", country_id: "LY", label: "+218", enabled: true }, // Libya
  { id: "+423", country_id: "LI", label: "+423", enabled: true }, // Liechtenstein
  { id: "+370", country_id: "LT", label: "+370", enabled: true }, // Lithuania
  { id: "+352", country_id: "LU", label: "+352", enabled: true }, // Luxembourg
  { id: "+853", country_id: "MO", label: "+853", enabled: true }, // Macau
  { id: "+389", country_id: "MK", label: "+389", enabled: true }, // Macedonia
  { id: "+261", country_id: "MG", label: "+261", enabled: true }, // Madagascar
  { id: "+265", country_id: "MW", label: "+265", enabled: true }, // Malawi
  { id: "+60", country_id: "MY", label: "+60", enabled: true }, // Malaysia
  { id: "+960", country_id: "MV", label: "+960", enabled: true }, // Maldives
  { id: "+223", country_id: "ML", label: "+223", enabled: true }, // Mali
  { id: "+356", country_id: "MT", label: "+356", enabled: true }, // Malta
  { id: "+692", country_id: "MH", label: "+692", enabled: true }, // Marshall Islands
  { id: "+222", country_id: "MR", label: "+222", enabled: true }, // Mauritania
  { id: "+230", country_id: "MU", label: "+230", enabled: true }, // Mauritius
  { id: "+262", country_id: "YT", label: "+262", enabled: true }, // Mayotte
  { id: "+52", country_id: "MX", label: "+52", enabled: true }, // Mexico
  { id: "+691", country_id: "FM", label: "+691", enabled: true }, // Micronesia
  { id: "+373", country_id: "MD", label: "+373", enabled: true }, // Moldova
  { id: "+377", country_id: "MC", label: "+377", enabled: true }, // Monaco
  { id: "+976", country_id: "MN", label: "+976", enabled: true }, // Mongolia
  { id: "+382", country_id: "ME", label: "+382", enabled: true }, // Montenegro
  { id: "+1-664", country_id: "MS", label: "+1-664", enabled: true }, // Montserrat
  { id: "+212", country_id: "MA", label: "+212", enabled: true }, // Morocco
  { id: "+258", country_id: "MZ", label: "+258", enabled: true }, // Mozambique
  { id: "+95", country_id: "MM", label: "+95", enabled: true }, // Myanmar
  { id: "+264", country_id: "NA", label: "+264", enabled: true }, // Namibia
  { id: "+674", country_id: "NR", label: "+674", enabled: true }, // Nauru
  { id: "+977", country_id: "NP", label: "+977", enabled: true }, // Nepal
  { id: "+31", country_id: "NL", label: "+31", enabled: true }, // Netherlands
  { id: "+599", country_id: "AN", label: "+599", enabled: true }, // Netherlands Antilles
  { id: "+687", country_id: "NC", label: "+687", enabled: true }, // New Caledonia
  { id: "+64", country_id: "NZ", label: "+64", enabled: true }, // New Zealand
  { id: "+505", country_id: "NI", label: "+505", enabled: true }, // Nicaragua
  { id: "+227", country_id: "NE", label: "+227", enabled: true }, // Niger
  { id: "+234", country_id: "NG", label: "+234", enabled: true }, // Nigeria
  { id: "+683", country_id: "NU", label: "+683", enabled: true }, // Niue
  { id: "+850", country_id: "KP", label: "+850", enabled: true }, // North Korea
  { id: "+47", country_id: "NO", label: "+47", enabled: true }, // Norway
  { id: "+968", country_id: "OM", label: "+968", enabled: true }, // Oman
  { id: "+92", country_id: "PK", label: "+92", enabled: true }, // Pakistan
  { id: "+680", country_id: "PW", label: "+680", enabled: true }, // Palau
  { id: "+970", country_id: "PS", label: "+970", enabled: true }, // Palestine
  { id: "+507", country_id: "PA", label: "+507", enabled: true }, // Panama
  { id: "+675", country_id: "PG", label: "+675", enabled: true }, // Papua New Guinea
  { id: "+595", country_id: "PY", label: "+595", enabled: true }, // Paraguay
  { id: "+51", country_id: "PE", label: "+51", enabled: true }, // Peru
  { id: "+63", country_id: "PH", label: "+63", enabled: true }, // Philippines
  { id: "+48", country_id: "PL", label: "+48", enabled: true }, // Poland
  { id: "+351", country_id: "PT", label: "+351", enabled: true }, // Portugal
  { id: "+1-787", country_id: "PR", label: "+1-787", enabled: true }, // Puerto Rico
  { id: "+974", country_id: "QA", label: "+974", enabled: true }, // Qatar
  { id: "+242", country_id: "CG", label: "+242", enabled: true }, // Republic of the Congo
  { id: "+40", country_id: "RO", label: "+40", enabled: true }, // Romania
  { id: "+7", country_id: "RU", label: "+7", enabled: true }, // Russia
  { id: "+250", country_id: "RW", label: "+250", enabled: true }, // Rwanda
  { id: "+590", country_id: "BL", label: "+590", enabled: true }, // Saint Barthélemy
  { id: "+290", country_id: "SH", label: "+290", enabled: true }, // Saint Helena
  { id: "+1-869", country_id: "KN", label: "+1-869", enabled: true }, // Saint Kitts and Nevis
  { id: "+1-758", country_id: "LC", label: "+1-758", enabled: true }, // Saint Lucia
  { id: "+590", country_id: "MF", label: "+590", enabled: true }, // Saint Martin
  { id: "+508", country_id: "PM", label: "+508", enabled: true }, // Saint Pierre and Miquelon
  { id: "+1-784", country_id: "VC", label: "+1-784", enabled: true }, // Saint Vincent and the Grenadines
  { id: "+685", country_id: "WS", label: "+685", enabled: true }, // Samoa
  { id: "+378", country_id: "SM", label: "+378", enabled: true }, // San Marino
  { id: "+239", country_id: "ST", label: "+239", enabled: true }, // São Tomé and Príncipe
  { id: "+966", country_id: "SA", label: "+966", enabled: true }, // Saudi Arabia
  { id: "+221", country_id: "SN", label: "+221", enabled: true }, // Senegal
  { id: "+381", country_id: "RS", label: "+381", enabled: true }, // Serbia
  { id: "+248", country_id: "SC", label: "+248", enabled: true }, // Seychelles
  { id: "+232", country_id: "SL", label: "+232", enabled: true }, // Sierra Leone
  { id: "+65", country_id: "SG", label: "+65", enabled: true }, // Singapore
  { id: "+1-721", country_id: "SX", label: "+1-721", enabled: true }, // Sint Maarten
  { id: "+421", country_id: "SK", label: "+421", enabled: true }, // Slovakia
  { id: "+386", country_id: "SI", label: "+386", enabled: true }, // Slovenia
  { id: "+677", country_id: "SB", label: "+677", enabled: true }, // Solomon Islands
  { id: "+252", country_id: "SO", label: "+252", enabled: true }, // Somalia
  { id: "+27", country_id: "ZA", label: "+27", enabled: true }, // South Africa
  { id: "+211", country_id: "SS", label: "+211", enabled: true }, // South Sudan
  { id: "+34", country_id: "ES", label: "+34", enabled: true }, // Spain
  { id: "+94", country_id: "LK", label: "+94", enabled: true }, // Sri Lanka
  { id: "+249", country_id: "SD", label: "+249", enabled: true }, // Sudan
  { id: "+597", country_id: "SR", label: "+597", enabled: true }, // Suriname
  { id: "+268", country_id: "SZ", label: "+268", enabled: true }, // Swaziland
  { id: "+46", country_id: "SE", label: "+46", enabled: true }, // Sweden
  { id: "+41", country_id: "CH", label: "+41", enabled: true }, // Switzerland
  { id: "+963", country_id: "SY", label: "+963", enabled: true }, // Syria
  { id: "+886", country_id: "TW", label: "+886", enabled: true }, // Taiwan
  { id: "+992", country_id: "TJ", label: "+992", enabled: true }, // Tajikistan
  { id: "+255", country_id: "TZ", label: "+255", enabled: true }, // Tanzania
  { id: "+66", country_id: "TH", label: "+66", enabled: true }, // Thailand
  { id: "+228", country_id: "TG", label: "+228", enabled: true }, // Togo
  { id: "+690", country_id: "TK", label: "+690", enabled: true }, // Tokelau
  { id: "+676", country_id: "TO", label: "+676", enabled: true }, // Tonga
  { id: "+1-868", country_id: "TT", label: "+1-868", enabled: true }, // Trinidad and Tobago
  { id: "+216", country_id: "TN", label: "+216", enabled: true }, // Tunisia
  { id: "+90", country_id: "TR", label: "+90", enabled: true }, // Turkey
  { id: "+993", country_id: "TM", label: "+993", enabled: true }, // Turkmenistan
  { id: "+1-649", country_id: "TC", label: "+1-649", enabled: true }, // Turks and Caicos Islands
  { id: "+688", country_id: "TV", label: "+688", enabled: true }, // Tuvalu
  { id: "+256", country_id: "UG", label: "+256", enabled: true }, // Uganda
  { id: "+380", country_id: "UA", label: "+380", enabled: true }, // Ukraine
  { id: "+971", country_id: "AE", label: "+971", enabled: true }, // United Arab Emirates
  { id: "+44", country_id: "GB", label: "+44", enabled: true }, // United Kingdom
  { id: "+1", country_id: "US", label: "+1", enabled: true }, // United States
  { id: "+598", country_id: "UY", label: "+598", enabled: true }, // Uruguay
  { id: "+998", country_id: "UZ", label: "+998", enabled: true }, // Uzbekistan
  { id: "+678", country_id: "VU", label: "+678", enabled: true }, // Vanuatu
  { id: "+379", country_id: "VA", label: "+379", enabled: true }, // Vatican City
  { id: "+58", country_id: "VE", label: "+58", enabled: true }, // Venezuela
  { id: "+84", country_id: "VN", label: "+84", enabled: true }, // Vietnam
  { id: "+681", country_id: "WF", label: "+681", enabled: true }, // Wallis and Futuna
  { id: "+212", country_id: "EH", label: "+212", enabled: true }, // Western Sahara
  { id: "+967", country_id: "YE", label: "+967", enabled: true }, // Yemen
  { id: "+260", country_id: "ZM", label: "+260", enabled: true }, // Zambia
  { id: "+263", country_id: "ZW", label: "+263", enabled: true }, // Zimbabwe
];

// Function to normalize the country codes
const normalizeCountryCode = (code) => {
  // Remove hyphens from codes like +1-684
  return code.replace("-", "");
};

export const ordered_country_codes = country_codes
  .sort((a, b) => {
    const codeA = normalizeCountryCode(a.id);
    const codeB = normalizeCountryCode(b.id);
    return codeA.localeCompare(codeB, undefined, { numeric: true });
  })
  .filter(
    (value, index, self) =>
      index === self.findIndex((item) => item.id === value.id)
  );
